<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for -->
  <div>
    <div class="pull-right" id="toolbar" style="width: 100%;">
      <button @click="showSettingsModal"
              class="btn btn-mini btn-white pull-right"
              v-if="showSettings">
        <i class="fa fa-gear"></i>
      </button>
      <div class="pull-right">
        <slot name="toolbar"></slot>
      </div>
      <div class="pull-right">
        <input class="form-control" placeholder="zoeken..." type="text" v-if="searchFields" v-model="query">
      </div>
    </div>

    <table class="table table-hover">
      <thead>
      <tr>
        <th v-if="canSelect">
          <input @change="selectAllRecords" type="checkbox" v-model="selectedPage">
        </th>
        <th :key="index" v-for="(column, index) in visibleColumns">
          <template v-if="column.sortKey">
            <i :class="{
            'fa fa-chevron-up' : chevronDirection(column, 'up'),
            'fa fa-chevron-down': chevronDirection(column, 'down'),
            'fa fa-chevron-right': chevronDirection(column, 'right')}"
               @click="sortColumn(column)"></i>
          </template>
          {{ column.title }}
        </th>
        <th v-if="actions"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="isLoading">
        <td :colspan="columnCount" class="text-center">
          <Loader :inline="true"/>
        </td>
      </tr>
      <tr v-else v-for="(record, index) in records" :key="index">
        <td v-if="canSelect">
          <input type="checkbox" v-model="itemSelection[record.id]">
        </td>
        <td :key="index" v-for="(column, index) in visibleColumns">
          <Cell :column="column" :record="record" @action="emitAction"/>
        </td>
        <td v-if="actions">
          <div class="pull-right" style="text-align:right;">
            <a :class="'btn-' + action.type" :key="index" @click="actionHandler(action, record)"
               class="btn btn-xs tw-ml-1"
               v-for="(action, index) in actions" v-if="recordHasConditionalKey(record, action)">
              <i v-if="action.icon" :class="'fa-' + action.icon" :title="action.title" class="fa"></i>
              <template v-if="action.label">{{ action.label }}</template>
            </a>
          </div>
        </td>
      </tr>
      </tbody>
    </table>

    <div class="row table-footer">
      <div class="col-sm-4 table-stats">
        <template v-if="canSelect">
          <span>{{ selectedItemCount }} items van {{ totalItemCount }} geselecteerd</span><br>
          <a @click="selectAllTheThings">Selecteer alle items</a> -
          <a @click="deselectAllTheThings">Selectie leegmaken</a>
        </template>
      </div>
      <div class="col-sm-6 text-center">
        <div class="btn-group" v-if="isPaginated">
          <button @click="previousPage" class="btn btn-white">
            <i class="fa fa-chevron-left"></i> Vorige
          </button>
          <span class="btn btn-white">
          {{ currentPage > maxPage ? maxPage : currentPage }} / {{ maxPage }}
        </span>
          <button @click="nextPage" class="btn btn-white">
            Volgende <i class="fa fa-chevron-right"></i>
          </button>
        </div>
      </div>
      <div class="col-sm-2 text-right">
      </div>
    </div>

    <Modal ref="settingsModal" title="Instellingen voor tabel" size="xl">
      <div style="display: block;">
        <h2>Zichtbare kolommen</h2>
        <div class="row">
          <div class="col-sm-4">
            <ul class="no-bullet">
              <template
                v-for="(column, index) in columns"
                v-if="columnSelection && columnSelection[column.key] && index % 3 === 0">
                <li :key="index">
                  <input type="checkbox"
                         :key="index"
                         v-model="columnSelection[column.key].visible"> {{ column.title }}
                </li>
              </template>
            </ul>
          </div>

          <div class="col-sm-4">
            <ul class="no-bullet">
              <template
                v-for="(column, index) in columns"
                v-if="columnSelection && columnSelection[column.key] && index % 3 === 1">
                <li :key="index">
                  <input type="checkbox"
                         :key="index"
                         v-model="columnSelection[column.key].visible"> {{ column.title }}
                </li>
              </template>
            </ul>
          </div>

          <div class="col-sm-4">
            <ul class="no-bullet">
              <template
                v-for="(column, index) in columns"
                v-if="columnSelection && columnSelection[column.key] && index % 3 === 2">
                <li :key="index">
                  <input type="checkbox"
                         :key="index"
                         v-model="columnSelection[column.key].visible"> {{ column.title }}
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>

      <div slot="footer">
        <button @click="saveSettings" class="btn btn-primary" type="button">Sluiten</button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Vue from 'vue'
import Modal from './Modal'
import Loader from './Loader'
import zip from 'lodash/zip'
import moment from 'moment'

Vue.component('Cell', {
  props: ['record', 'column'],
  template: `
    <component
      v-if="column.renderComponent"
      :is="column.renderComponent"
      :object="record"
      :value="cellValue"
      @action="emitAction"
    />
    <span v-else>{{ cellValue }}</span>`,
  methods: {
    emitAction (payload) {
      this.$emit('action', payload)
    }
  },
  computed: {
    cellValue () {
      const keys = this.column?.key?.split('.')
      let subsetRecord = this.record
      if (keys) {
        keys.forEach(keyItem => {
          if (subsetRecord) subsetRecord = subsetRecord[keyItem]
        })
      }
      return subsetRecord
    }
  }
})

Vue.component('dg-check', {
  props: ['value'],
  template: '<span><i class="fa fa-check" v-if="value"></i><i class="fa fa-times" v-else></i></span>'
})

Vue.component('dg-contact-gender', {
  props: ['value'],
  template: '<span>{{ gender }}</span>',
  computed: {
    gender () {
      const genders = {
        U: 'Onbekend',
        M: 'Man',
        V: 'Vrouw'
      }
      return genders[this.value]
    }
  }
})

Vue.component('dg-address', {
  props: ['value', 'object'],
  template: '<span>{{ object.street }} {{ object.number }} {{ object.box }}</span>'
})

Vue.component('dg-city', {
  props: ['value', 'object'],
  template: '<span v-if="object.city">{{ object.city.zip_code }} {{ object.city.name }}</span>'
})

export default {
  name: 'DataGrid',
  components: { Loader, Modal },
  props: {
    id: {
      default: null
    },
    data: {
      default: null
    },
    columns: {
      default: () => []
    },
    actions: {
      default: null
    },
    searchFields: {
      default: null
    },
    canSelect: {
      default: false
    },
    pageSize: {
      default: null
    },
    showSettings: {
      default: true
    }
  },
  data () {
    return {
      itemSelection: {},
      columnSelection: {},
      currentPage: 1,
      selectedPage: false,
      query: '',
      isLoading: true,
      sortingDirection: null,
      sortedColumn: null,
      sortKey: null
    }
  },
  mounted () {
    this.setupColumns()

    // When going back a page the component is rebuilt but the data watcher
    // doesn't trigger leaving te component stuck in loading
    this.data ? this.isLoading = false : this.isLoading = true
  },
  watch: {
    data () {
      if (!this.data) {
        this.isLoading = true
      } else {
        this.isLoading = false
        this.itemSelection = {}
        this.data.forEach(item => {
          Vue.set(this.itemSelection, item.id, false)
        })
      }
    },
    columns () {
      this.setupColumns()
    },
    selectedItemCount () {
      const recordIndexes = Object.keys(this.itemSelection)
      const recordsSelected = Object.values(this.itemSelection)
      const zippedRecords = zip(recordIndexes, recordsSelected)
      const records = []

      for (const zippedRecord of zippedRecords) {
        const [recordId, recordSelected] = zippedRecord
        if (recordSelected) {
          const record = this.data.filter(record => record.id === parseInt(recordId))[0]
          records.push(record)
        }
      }
      this.$emit('datagrid-item-selection-updated', records)
    }
  },
  computed: {
    columnCount () {
      if (this.canSelect) {
        return this.visibleColumns.length + 1
      }
      return this.visibleColumns.length
    },
    filteredRecords () {
      if (this.query && this.data) {
        const q = this.query.toLowerCase()
        return this.data.filter((record) => {
          let found = false
          this.searchFields.forEach((field) => {
            const recordValue = this.subsetRecordOnSearchField(record, field)
            if (recordValue.toLowerCase().includes(q)) found = true
          })
          return found
        })
      }
      return this.data || []
    },
    records () {
      const filteredRecords = this.sortData(this.filteredRecords)
      if (this.isPaginated && filteredRecords) {
        const start = this.pageSize * (this.currentPage - 1)
        const end = start + this.pageSize
        return filteredRecords.slice(start, end)
      }
      return filteredRecords
    },
    visibleColumns () {
      return Object.values(this.columnSelection).filter(column => column.visible === true)
    },
    selectedItems () {
      return Object.values(this.itemSelection).filter(item => item === true)
    },
    selectedItemCount () {
      return this.selectedItems.length
    },
    totalItemCount () {
      return this.data ? this.data.length : 0
    },
    isPaginated () {
      return this.pageSize && this.pageSize > 0
    },
    maxPage () {
      if (this.filteredRecords) {
        return Math.ceil(this.filteredRecords.length / this.pageSize)
      }
      return 0
    },
    configName () {
      return `datagrid-${this.id}`
    }
  },
  methods: {

    /* Sets the sorted column and its sorting direction */
    sortColumn (column) {
      // No column is currently sorted
      // Set new sort column

      const noSortedColumn = !this.sortedColumn
      const sortedOnCurrentColumn = this.sortedColumn === column.title

      // Set sorting descending if we have no sorting applied or we have a sorting on another column
      if (noSortedColumn) {
        this.sortedColumn = column.title
        this.sortingDirection = 'descending'
        this.sortKey = column.sortKey

        // Alternate the sorting direction if we have sorting on the current column
      } else if (sortedOnCurrentColumn) {
        this.sortingDirection = this.sortingDirection === 'ascending' ? 'descending' : 'ascending'
      } else {
        console.error('Invalid sorting attempt.')
      }
    },
    /**
     * Check if a chevron should be set in a certain direction for a column in a table
     *
     * @param column: The column for which to check the chevron position
     * @param direction: The direction to be check on
     * @returns: True if the column should be sorted in the 'direction' we specified else False
     * */
    chevronDirection (column, direction) {
      const sortedOnCurrentColumn = this.sortedColumn === column.title

      if (direction === 'up') {
        return (sortedOnCurrentColumn) && this.sortingDirection === 'ascending'
      } else if (direction === 'down') {
        return sortedOnCurrentColumn && this.sortingDirection === 'descending'
      } else if (direction === 'right') {
        return !sortedOnCurrentColumn
      } else {
        console.error('Invalid sorting direction specified.')
      }
    },

    /**
     * A sorting key can use dot notation. In such a way we can sort on nested keys in a table record. The current
     * function retrieves the values for a key using dot notation over a body of records. The value's record is
     * accompanied with the value in the return statement.
     *
     * @param records: The records for which to retrieve the values
     * @returns: A value for a record equal to the sort key and the record from which the value was extracted
     * */
    getRecordValues (records) {
      const keyFragments = this.sortKey.split('.')
      // Build a dict of a record and it's sorting value
      const recordValues = []
      records.forEach(record => {
        let value = record
        keyFragments.forEach(keyFragment => {
          if (value[keyFragment]) {
            value = value[keyFragment]
            // If key cannot be found then use an empty string, for instance when no next activity
          }
        })
        recordValues.push({ record: record, value: value })
      })
      return recordValues
    },
    /** Format record values for sorting. If the values are valid datetimes, convert them to datetime objects. If
     * they are strings, convert them to lowercase.
     *
     * @param valueOne: The first value to be formatted for sorting
     * @param valueTwo: The second value to be formatted for sorting
     * */
    formatRecordValues (valueOne, valueTwo) {
      const valueOneMoment = moment(valueOne)
      const valueTwoMoment = moment(valueTwo)
      let isMoment = false
      if (valueOneMoment.isValid() && valueTwoMoment.isValid()) {
        valueOne = valueOneMoment
        valueTwo = valueTwoMoment
        isMoment = true
      } else {
        valueOne = valueOne.toLowerCase()
        valueTwo = valueTwo.toLowerCase()
        isMoment = false
      }
      return [valueOne, valueTwo, isMoment]
    },
    /** Sort all records based on their value adn the applied sortingDirection.
     *
     * @param recordValues: The recordValues to be sorted.
     * */
    sortRecordValues (recordValues) {
      return recordValues.sort((valueOne, valueTwo) => {
        const [valueOneFormatted, valueTwoFormatted, isMoment] = this.formatRecordValues(valueOne.value, valueTwo.value)
        const isAfter = isMoment ? (valueOneFormatted.isAfter(valueTwo)) : (valueOneFormatted > valueTwoFormatted)
        const isBefore = isMoment ? (valueOneFormatted.isBefore(valueTwo)) : (valueOneFormatted < valueTwoFormatted)
        if (this.sortingDirection === 'ascending') {
          if (isAfter) {
            return 1
          } else if (isBefore) {
            return -1
          } else {
            return 0
          }
        } else if (this.sortingDirection === 'descending') {
          if (isBefore) {
            return 1
          } else if (isAfter) {
            return -1
          } else {
            return 0
          }
        } else {
          console.error('Invalid sorting direction')
        }
      })
    },
    /** Sorts the data according to sortKey and a sortingDirection */
    sortData (records) {
      // We have a sorted column
      if (this.sortedColumn) {
        const recordValues = this.getRecordValues(records)
        const sortedRecordValues = this.sortRecordValues(recordValues)
        return sortedRecordValues.map(recordKeyItem => recordKeyItem.record)
        // We don't have a sorted column
      } else {
        return records
      }
    },
    emitAction (actionPayload) {
      if (!('type' in actionPayload)) {
        const errorMessage = "When emitting an 'action' from inside a datagrid rendercomponent, a 'type' key " +
          'must be passed in the emit payload. This type is the identifier for the action.'
        console.error(errorMessage)
      }
      const type = actionPayload.type
      const data = actionPayload.data
      this.$emit(type, data)
    },

    recordHasConditionalKey (record, action) {
      if (action.conditionalKey) {
        return !!record[action.conditionalKey]
      } else {
        return true
      }
    },
    subsetRecordOnSearchField (record, searchField) {
      let subsetRecord = record
      const keys = searchField.split('.')
      for (const key of keys) {
        if (subsetRecord) {
          subsetRecord = subsetRecord[key]
        }
      }
      return subsetRecord
    },

    actionHandler (action, item) {
      this.$emit(action.key, item)
    },
    selectAllRecords (event) {
      let v = false
      if (event.target.checked) v = true
      this.filteredRecords.forEach(record => Vue.set(this.itemSelection, record.id, v))
    },
    selectAllTheThings () {
      this.itemSelection = {}
      this.data.forEach(record => Vue.set(this.itemSelection, record.id, true))
    },
    deselectAllTheThings () {
      for (const key of Object.keys(this.itemSelection)) {
        Vue.set(this.itemSelection, key, false)
      }
      this.selectedPage = false
    },
    previousPage () {
      if (this.currentPage > 1) {
        this.currentPage -= 1
        this.selectedPage = false
      }
    },
    nextPage () {
      if (this.currentPage < this.maxPage) {
        this.currentPage += 1
        this.selectedPage = false
      }
    },
    setupColumns () {
      this.columnSelection = {}
      const savedColumnConfig = this.loadSettings().columns
      this.columns.forEach(column => {
        let status = column.visible === undefined || column.visible
        if (savedColumnConfig[column.key] !== undefined) status = savedColumnConfig[column.key]
        const c = { ...column, visible: status }
        Vue.set(this.columnSelection, column.key, c)
      })
    },
    showSettingsModal () {
      this.$refs.settingsModal.show()
    },
    saveSettings () {
      this.$refs.settingsModal.hide()

      const data = { columns: {} }
      for (const [key, column] of Object.entries(this.columnSelection)) {
        data.columns[key] = column.visible
      }
      localStorage.setItem(this.configName, JSON.stringify(data))
    },
    loadSettings () {
      let data = localStorage.getItem(this.configName)
      data = JSON.parse(data)
      if (!data) {
        return {
          columns: {}
        }
      }
      return data
    },
    getSelectedRecords () {
      const recordIds = Object.keys(this.itemSelection)
      const recordsSelectedBool = Object.values(this.itemSelection)
      const recordIdsSelected = []
      recordsSelectedBool.forEach((recordSelectedBool, index) => {
        if (recordSelectedBool) recordIdsSelected.push(parseInt(recordIds[index]))
      })
      return this.data.filter(record => recordIdsSelected.includes(parseInt(record.id)))
    }
  }
}
</script>

<style scoped>
.table-stats {
  padding: 6px 12px 6px 25px
}

.table-footer {
  border-top: 1px solid #DDDDDD;
  padding: 4px;
  margin: 0 3px 0 3px;
}

.no-bullet {
  list-style: none;
}
</style>
